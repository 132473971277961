import { api, apiEndPoints } from '@app/api'
import { ApiResponse, ApiResult } from '@app/types'
import { AlertAction, IAlert } from './types'
import { Dispatch } from 'redux'
import { setSpinner } from '../actions'
import { ITableCommonParams } from '../commonInterfaces'

export function getAlerts(
  dispatch: Dispatch,
  params: ITableCommonParams
): Promise<ApiResult<IAlert>> {
  dispatch(setSpinner(true))

  return api
    .get<ApiResponse<ApiResult<IAlert>>>(apiEndPoints.getAlerts, {
      params: params,
    })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject()
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function saveAlert(dispatch: Dispatch, body: IAlert): Promise<IAlert> {
  const method = body.id ? 'PUT' : 'POST'
  const url = body.id ? apiEndPoints.updateAlert(body.id) : apiEndPoints.createAlert

  dispatch(setSpinner(true))

  return api<ApiResponse<IAlert>>({
    method: method,
    url: url,
    data: body,
  })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject()
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function deleteAlert(dispatch: Dispatch, id: number): Promise<unknown> {
  dispatch(setSpinner(true))

  return api.delete(apiEndPoints.deleteAlert(id)).finally(() => {
    dispatch(setSpinner(false))
  })
}

export function takeAlertAction(
  dispatch: Dispatch,
  id: number,
  action: AlertAction
): Promise<unknown> {
  dispatch(setSpinner(true))

  return api.post(apiEndPoints.interactAlert(id), { actionTaken: action }).finally(() => {
    dispatch(setSpinner(false))
  })
}

export function deleteUserInteractions(dispatch: Dispatch, id: number): Promise<unknown> {
  dispatch(setSpinner(true))

  return api.delete(apiEndPoints.deleteUserInteractions(id)).finally(() => {
    dispatch(setSpinner(false))
  })
}
