import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Roles, SocialProviderTypes } from '../../commonEnums'
import { routePath } from '../../routePaths'
import { clearNotifications, setSpinner } from '../../actions'
import { getUserExternalLogins } from '../../usersProfile/profileDetails/socialLogins/actions'
import { externalLogin, setSocialLoginStatus, getUserDetails, loggedInUserId } from './actions'
import { IParticipantProfileDetails } from '@app/containers/participantPages/reducer'
import { RootState } from '@app/store/configureStore'
import {
  getParticipantProfileInfo,
  handleParticipantProfileNavigation,
} from '@app/commonUtils/participantProfileHelper'
import { ucQueryKey } from '@app/containers/participantPages/useParticipantProfileAuth'

interface IExternalLoginProps {
  profile: any
  provider: string
  isParticipantPage?: boolean
  uniqueCode?: string
}

export const ExternalUserLogin: React.FC<IExternalLoginProps> = (props) => {
  const { profile, provider, isParticipantPage, uniqueCode } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formattedProvider = provider.charAt(0).toUpperCase() + provider.slice(1)

  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails
  )
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)

  let sub: any = ''
  let accessCode: string = ''
  if (provider === SocialProviderTypes.MICROSOFT) {
    sub = profile.id_token ? JSON.parse(atob(profile.id_token.split('.')[1])).oid : null
  } else {
    sub = profile.sub
    accessCode = profile.code
  }

  const payload = {
    authProvider: formattedProvider,
    providerKey: sub,
    providerAccessToken: profile.access_token,
    providerCode: accessCode,
    provider,
    returnUrl: '/App',
    singleSignIn: true,
  }

  const handleParticipantNavigation = async (
    uniqueCode: string,
    passwordCheckRequired = false
  ): Promise<void> => {
    let profileDetails: IParticipantProfileDetails = participantProfileDetails

    const response = await getParticipantProfileInfo(
      uniqueCode,
      participantProfileDetails,
      dispatch,
      true
    )
    if (response) {
      profileDetails = response
    }

    // For new participant, if language is not set, first select language page, then demographic page (check while external login)
    if (!profileDetails.languageName && passwordCheckRequired) {
      navigate(`${routePath.dr}?${ucQueryKey}=${uniqueCode}`)
    } else handleParticipantProfileNavigation(uniqueCode, profileDetails, navigate)
  }

  useEffect(() => {
    dispatch(clearNotifications(''))
    if (isParticipantPage) {
      externalLogin(dispatch, payload)
        .then((response) => {
          dispatch(setSpinner(true))
          if (response?.success === true) {
            handleParticipantNavigation(uniqueCode!)
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    } else {
      externalLogin(dispatch, payload)
        .then((response) => {
          dispatch(setSpinner(true))
          if (response?.success === true) {
            getUserDetails(dispatch, false, userLanguage.userLanguageCode).then((response) => {
              const UserID: number = response.userId
              dispatch(loggedInUserId(UserID))

              const roles: string[] = response?.userRoles ?? []
              const returnTo = sessionStorage.getItem('returnTo') ?? ''

              if (roles.length !== 0) {
                if (
                  roles.includes(Roles.Facilitator) &&
                  roles.includes(Roles.Participant) &&
                  !roles.includes(Roles.Admin)
                ) {
                  if (window.location.pathname === routePath.signIn) navigate(routePath.switchRoles)
                  else {
                    getUserExternalLogins(dispatch).then((socialLogins: string[]) => {
                      dispatch(setSocialLoginStatus(socialLogins))
                    })
                  }
                } else if (roles.includes(Roles.Admin) || roles.includes(Roles.Facilitator)) {
                  if (!returnTo || returnTo === routePath.signIn) {
                    if (window.location.pathname !== routePath.userProfile) navigate(routePath.home)
                    else {
                      getUserExternalLogins(dispatch).then((socialLogins: string[]) => {
                        dispatch(setSocialLoginStatus(socialLogins))
                      })
                    }
                  } else {
                    if (window.location.pathname !== routePath.userProfile) {
                      navigate(returnTo)
                    }
                  }
                  if (returnTo) sessionStorage.removeItem('returnTo')
                } else if (roles.length === 1 && roles.includes(Roles.Participant)) {
                  getUserDetails(dispatch, true, response.userLanguageCode).then((response) => {
                    if (response) {
                      const { profileParticipantId, isOverviewPage, uniqueCode } = response

                      if (window.location.pathname !== routePath.participantUserProfile) {
                        if (isOverviewPage || !profileParticipantId) {
                          navigate(routePath.participantDashboard)
                        } else {
                          handleParticipantNavigation(uniqueCode, true)
                        }
                      } else {
                        getUserExternalLogins(dispatch).then((socialLogins: string[]) => {
                          dispatch(setSocialLoginStatus(socialLogins))
                        })
                      }
                    }
                  })
                } else navigate(routePath.home)
              }
            })
          }
        })
        .finally(() => {
          dispatch(setSpinner(false))
        })
    }
  }, [profile.email])

  return <React.Fragment />
}
