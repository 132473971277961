import React from 'react'
import Alert from 'react-bootstrap/Alert'

import { KTSVG } from '@app/commonUtils/metronic'
import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { IParticipants } from '../hooks'
import Checkbox from '../../../components/formComponents/checkbox'
import { useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'

interface IStartPageProps {
  languageText: ITranslationObject
  startPresentation: () => void
}

interface IFinalPageProps {
  languageText: ITranslationObject
  participants: IParticipants[]
  participantPublished: boolean
  handleSelectAllParticipantsForDelivery: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSelectParticipantForDelivery: (
    e: React.ChangeEvent<HTMLInputElement>,
    courseDelegateId: number
  ) => void
  publishParticipants: () => void
  handleFinalPageBackBtn: () => void
}

export const StartPage = (props: IStartPageProps): JSX.Element => {
  const { languageText, startPresentation } = props
  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)

  return (
    <div className='d-flex justify-content-center align-items-center mt-5 pt-5'>
      <button
        className='btn btn-primary'
        onClick={startPresentation}
        disabled={userDetails.isManager}
      >
        {getLanguageValue(languageText, 'Start Presentation')}
      </button>
    </div>
  )
}

export const FinalPage = (props: IFinalPageProps) => {
  const {
    languageText,
    participants,
    participantPublished,
    handleSelectAllParticipantsForDelivery,
    handleSelectParticipantForDelivery,
    publishParticipants,
    handleFinalPageBackBtn,
  } = props

  return (
    <div className='d-flex justify-content-center align-items-center mt-5 pt-5'>
      {participantPublished && (
        <Alert variant='success'>
          {getLanguageValue(languageText, 'The selected profiles are delivered')}.
        </Alert>
      )}

      {!participantPublished && (
        <div className='card mb-5 mb-xl-8 p-3'>
          <div className='card-header border-0'>
            <h3 className='card-title align-items-start'>
              <span>
                {getLanguageValue(languageText, 'Select the participants to publish the profiles')}.
              </span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th>
                      <Checkbox
                        labelClass='mx-5'
                        value={participants.every((participant) => participant.checked)}
                        label={getLanguageValue(languageText, 'Participants Name')}
                        handleCheckboxChange={handleSelectAllParticipantsForDelivery}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {participants.map((participant) => (
                    <tr key={participant.profileParticipantId}>
                      <td>
                        <Checkbox
                          labelClass='mx-5'
                          label={`${participant.forename} ${participant.surname}`}
                          value={participant.checked}
                          handleCheckboxChange={(e) =>
                            handleSelectParticipantForDelivery(e, participant.profileParticipantId)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <div onClick={handleFinalPageBackBtn}>
              <KTSVG path='/media/icons/duotune/arrows/arr002.svg' />
            </div>
            <button className='btn btn-success w-25' onClick={publishParticipants}>
              {getLanguageValue(languageText, 'Publish')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
