import { Roles } from '../containers/commonEnums'

export function isLoggedInRoleAdmin(loggedInUserRole: Roles | null): boolean {
  return loggedInUserRole === Roles.Admin
}

export function isLoggedInRoleFacilitator(loggedInUserRole: Roles | null): boolean {
  return loggedInUserRole === Roles.Facilitator
}

export function isLoggedInRoleParticipant(loggedInUserRole: Roles | null): boolean {
  return loggedInUserRole === Roles.Participant
}
