import { classNames } from '@app/containers/utils'
import * as React from 'react'

export type DropdownMenuItem = {
  name: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void
  disabled?: boolean
  title?: string
}

type Props = {
  icon?: React.ReactNode
  id?: string
  items: ReadonlyArray<DropdownMenuItem>
}

export const DropdownMenu: React.FC<Props> = (props) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState(false)

  function handleClickOutside(event: MouseEvent): void {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  function onClick(event: React.MouseEvent<HTMLAnchorElement>, item: DropdownMenuItem): void {
    event.preventDefault()
    event.stopPropagation()

    if (item.disabled) {
      return
    }

    item.onClick(event)
  }

  const icon = props.icon || <i className='bi bi-gear-fill' />

  /** @see https://github.com/twbs/bootstrap/issues/33636 */

  return (
    <div id={props.id} className='dropdown position-static' ref={dropdownRef}>
      <a className='btn btn-light' onClick={() => setOpen(!open)}>
        {icon}
      </a>
      <ul
        className={classNames({
          'dropdown-menu border-0 shadow-sm ': true,
          'd-block': open,
        })}
        tabIndex={-1}
      >
        {props.items.map((item, idx) => {
          const anchorClazz = classNames({
            'dropdown-item': true,
            disabled: item.disabled,
          })

          return (
            <li key={idx} title={item.title}>
              <a
                className={anchorClazz}
                onClick={(event) => onClick(event, item)}
                role={item.disabled ? undefined : 'button'}
              >
                {item.name}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
