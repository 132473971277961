import clsx from 'clsx'
import React from 'react'

interface ICheckboxProps {
  label?: string
  id?: string
  name?: string
  value?: boolean
  className?: string
  inputClass?: string
  labelClass?: string
  disable?: boolean
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  title?: string
}

const Checkbox = (props: ICheckboxProps) => {
  const {
    label,
    id,
    name,
    value,
    disable,
    className,
    inputClass,
    labelClass,
    handleCheckboxChange,
  } = props

  return (
    <label
      className={clsx('form-check d-flex align-items-center p-0', className && className)}
      role={!disable ? 'button' : ''}
      title={props.title}
    >
      <input
        id={id || ''}
        className={clsx('form-check-input fs-3 m-0', inputClass && inputClass)}
        type='checkbox'
        role='button'
        name={name ?? 'name'}
        checked={value}
        onChange={handleCheckboxChange}
        disabled={disable ?? false}
      />
      {label && (
        <div className={clsx('form-check-label ps-1 mt-1 ms-1', labelClass && labelClass)}>
          {label}
        </div>
      )}
    </label>
  )
}

export default Checkbox
