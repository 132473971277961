import { Dispatch } from 'redux'
import { IConsent } from '@app/containers/auth/signUp/reducer'
import { setSpinner } from '../actions'
import { api } from '@app/api'
import endPoints from '@app/api/end-points'
import { ApiResponse, ApiResult } from '@app/types'
import { ITableCommonParams } from '../commonInterfaces'

export function getConsents(
  dispatch: Dispatch,
  params: ITableCommonParams
): Promise<ApiResult<IConsent>> {
  dispatch(setSpinner(true))

  return api
    .get<ApiResponse<ApiResult<IConsent>>>(endPoints.getConsents, { params: params })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data)
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function answerConsent(
  consentId: number,
  isAccepted: boolean,
  dispatch: Dispatch
): Promise<ReadonlyArray<IConsent>> {
  dispatch(setSpinner(true))

  return api
    .post<ApiResponse<ReadonlyArray<IConsent>>>(endPoints.answerConsent(consentId), {
      isAccepted: isAccepted,
    })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data)
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function createConsent(dispatch: Dispatch, body: IConsent): Promise<unknown> {
  dispatch(setSpinner(true))

  return api
    .post<ApiResponse<void>>(endPoints.createConsent, body)
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data)
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function updateConsent(dispatch: Dispatch, id: number, body: IConsent): Promise<unknown> {
  dispatch(setSpinner(true))

  return api
    .put<ApiResponse<void>>(endPoints.updateConsent(id), body)
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data)
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}

export function deleteConsent(dispatch: Dispatch, id: number): Promise<unknown> {
  dispatch(setSpinner(true))

  return api
    .delete<ApiResponse<void>>(endPoints.updateConsent(id))
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data)
      }
      return res.data.result
    })
    .finally(() => {
      dispatch(setSpinner(false))
    })
}
