import { Dispatch } from 'redux'

import {
  getParticipantFormInfo,
  setParticipantProfileDetails,
} from '@app/containers/participantPages/actions'
import { IParticipantProfileDetails } from '@app/containers/participantPages/reducer'
import { NavigateFunction } from 'react-router-dom'
import { routePath } from '@app/containers/routePaths'
import { ucQueryKey } from '@app/containers/participantPages/useParticipantProfileAuth'

export interface IParticipantProfileResponse {
  languageName: string
  languageId: number
  participantId: number
  profileParticipantId: number
  isPasswordSet: boolean
  isDemographicsFilled: boolean
  isFormFilledData: boolean // some part of form is filled
  isFormFilled: boolean // full form is filled & submitted
  isParticipant: boolean
  emailAddress: string
  noOfRespondents: number
  participantInviteColleagues: boolean
  participantInviteExternal: boolean
  participantInviteOtherParticipant: boolean
  roleId: number
  roleText: string
  isProfileRoleEnabled: boolean
  presentationId: number
  profileParticipantLocked: boolean
  // Respondents
  profileRespondentId: number
  participantName: string
  isProfileDelivered: boolean
}

export const getParticipantProfileInfo = async (
  uniqueCode: string,
  participantProfileDetails: IParticipantProfileDetails,
  dispatch: Dispatch,
  isSocialLogin = false
): Promise<IParticipantProfileDetails> => {
  let updatedProfileDetails: IParticipantProfileDetails = participantProfileDetails

  await getParticipantFormInfo(uniqueCode, dispatch).then((response) => {
    if (response) {
      const updatedParticipantProfileDetails: IParticipantProfileDetails = {
        ...participantProfileDetails,
        languageName: response.languageName,
        participantName: response.participantName,
        isPasswordSet: response.isPasswordSet,
        isDemographicsFilled: response.isDemographicsFilled,
        isFormFilledData: response.isFormFilledData,
        isFormFilled: response.isFormFilled,
        isParticipant: true,
        profileParticipantId: response.profileParticipantId,
        emailAddress: response.emailAddress,
        noOfRespondents: response.noOfRespondents,
        participantInviteColleagues: response.participantInviteColleagues,
        participantInviteExternal: response.participantInviteExternal,
        participantInviteOtherParticipant: response.participantInviteOtherParticipant,
        roleId: response.isProfileRoleEnabled === false ? 6 : response.roleId, // If Role settings is disabled, noOfRespondents = 6
        roleText: response.roleText,
        isProfileRoleEnabled: response.isProfileRoleEnabled === false ? false : true,
        presentationId: response.presentationId,
        profileParticipantLocked: response.profileParticipantLocked,
        isSocialLogin,
      }

      dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails))
      updatedProfileDetails = updatedParticipantProfileDetails
    }
  })

  return updatedProfileDetails
}

export const handleParticipantProfileNavigation = (
  uniqueCode: string,
  profileDetails: IParticipantProfileDetails,
  navigate: NavigateFunction
): void => {
  if (!profileDetails) return

  // For new participant, if language is not set, first select language page, then demographic page
  if (!profileDetails.languageName) {
    navigate(`${routePath.dr}?${ucQueryKey}=${uniqueCode}`)
  }

  if (profileDetails.presentationId) {
    // Profile Presentation detail page
    navigate(`${routePath.participantProfilePresentation}?${ucQueryKey}=${uniqueCode}`)
  }

  // If form already filled, go to invite respondents page
  else if (profileDetails.isFormFilled) {
    navigate(`${routePath.participantInviteRespondents}?${ucQueryKey}=${uniqueCode}`)
  }

  // If form is partially filled, go to self form page directly
  else if (profileDetails.isFormFilledData) {
    navigate(`${routePath.participantSelfForm}?${ucQueryKey}=${uniqueCode}`)
  }

  // If demographic page is not filled, go to welcome page
  else if (!profileDetails.isDemographicsFilled) {
    navigate(`${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`)
  }

  // If demographic page is filled, go to role page
  else if (profileDetails.isDemographicsFilled) {
    navigate(`${routePath.participantRole}?${ucQueryKey}=${uniqueCode}`)

    // If none of the above, go to welcome page
  } else {
    navigate(`${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`)
  }
}
