import React from 'react'

import { ITranslationObject, getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '@app/components/modals/modalComponent'

interface ICancelPresentationModalProps {
  languageText: ITranslationObject
  isParticipantDelivery?: boolean
  handleCloseModal: () => void
  handleConfirmCancelPresentation: () => void
}

export const CancelPresentationModal: React.FC<ICancelPresentationModalProps> = (props) => {
  const { languageText, isParticipantDelivery, handleCloseModal, handleConfirmCancelPresentation } =
    props

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          languageText,
          !isParticipantDelivery ? 'Cancel Presentation' : 'Close Presentation'
        )}
        submitButtonText={getLanguageValue(languageText, 'Yes')}
        cancelButtonText={getLanguageValue(languageText, 'No')}
        handleSubmitClick={handleConfirmCancelPresentation}
        handleCancelClick={handleCloseModal}
        isSubmitDangerButton={true}
      >
        {getLanguageValue(
          languageText,
          !isParticipantDelivery
            ? 'Do you want to leave the presentation'
            : 'Do you want to close the presentation'
        )}
        ?
      </ModalComponent>
    </>
  )
}
