import * as React from 'react'
import { createDismissRatingAction } from './reducer'
import { ModalComponent } from '@app/components/modals/modalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { api, apiEndPoints } from '@app/api'
import TextArea from '@app/components/formComponents/textArea'
import { setNotification, setSpinner } from '../actions'
import { classNames } from '../utils'
import { ModelType } from '../commonInterfaces'

type Props = {
  modelType: ModelType
  modelId: number
  modelName: string
}

const RATING_VALUES = [1, 2, 3, 4, 5] as const
type RatingValue = (typeof RATING_VALUES)[number]

type RatingDto = {
  modelType: ModelType
  modelId: number
  value: RatingValue
  comment: string
}

export const RatingModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const lang = useSelector((state: RootState) => state.mainReducer.languageText)
  const [value, setValue] = React.useState<RatingValue | undefined>(undefined)
  const [comment, setComment] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  function handleDismiss(event: React.MouseEvent): void {
    dispatch(createDismissRatingAction())
  }

  function handleSubmit(event: React.MouseEvent): void {
    if (typeof value === 'undefined') {
      return
    }

    dispatch(setSpinner(true))
    setIsSubmitting(true)

    const body: RatingDto = {
      modelType: props.modelType,
      modelId: props.modelId,
      value: value,
      comment: comment,
    }

    api
      .post(apiEndPoints.submitRating, body)
      .catch((err) => {
        dispatch(setNotification(err))
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch(setSpinner(false))
        dispatch(createDismissRatingAction())
        setIsSubmitting(false)
      })
  }

  const header = `${getLanguageValue(lang, 'Rate')} "${props.modelName}"`

  const stars = RATING_VALUES.map((starValue, idx) => {
    const iconClazz = classNames({
      'bi text-dark': true,
      'bi-star': typeof value === 'undefined' || starValue > value,
      'bi-star-fill': typeof value !== 'undefined' && starValue <= value,
    })

    return (
      <div
        key={idx}
        className='col text-center'
        role='button'
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()

          setValue(starValue)
        }}
      >
        <i className={iconClazz} style={{ fontSize: '40px' }} />
      </div>
    )
  })

  return (
    <ModalComponent
      headerText={header}
      handleCancelClick={handleDismiss}
      cancelButtonText={getLanguageValue(lang, 'Cancel')}
      handleSubmitClick={handleSubmit}
      submitButtonText={getLanguageValue(lang, 'Submit')}
      submitButtonDisabled={isSubmitting || typeof value === 'undefined'}
    >
      <div className='row mb-6'>{stars}</div>
      <TextArea
        label={getLanguageValue(lang, 'Comment')}
        placeholder={getLanguageValue(lang, 'Comment')}
        value={comment}
        onChange={(event) => {
          setComment(event.target.value)
        }}
      />
    </ModalComponent>
  )
}
