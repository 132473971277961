import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HubConnectionBuilder } from '@microsoft/signalr'

import {
  getAllPresentationSlides,
  UpdateParticipantScreen,
  updateCoursePresentationStatus,
  getPresentationStatus,
  getAllParticipants,
  deliverProfileParticipants,
  getParticipantCurrentSlide,
  getFacilitatorPresentation,
} from './actions'
import { RootState } from '@app/store/configureStore'
import { setNotification, setSpinner } from '../actions'
import { CoursePresentationStatus } from '../profileList/profileEnums'
import { unsafeRenderPresentationSlide } from '@app/commonUtils/renderHtmlHelper'
import { pIdQueryKey } from '@app/consts'
import { PresentationPosition, usePresentationControls } from '../presentationsList/hooks'

export interface IParticipantCurrentSlideParams {
  slideId: number
  participantId: number
  languageCode: string
}

export interface IDeliverProfileParticipantsParams {
  courseId: number
  coursePresentationId: number
  finishedMsg: string
  languageCode: string
}

export interface IPresentationStepRule {
  selectionClass: string
  action: 'add' | 'remove'
  actionClass: string
}

interface IPresentationSlideSteps {
  step: string
  stepRules: IPresentationStepRule[]
}

export interface IPresentationSlide {
  id: number
  description: string
  html: string
  slideNumber: number
  slideSteps: IPresentationSlideSteps[]
  notes: string
}

export interface IParticipants {
  profileParticipantId: number
  courseId: number
  forename: string
  surname: string
  email: string
  participantId: number
  presentation: number
  presentationId: number
  checked: boolean
  isOnline: boolean
}

interface IParticipantsStatus {
  id: number
  isOnline: boolean
}

interface IPresentationData {
  presentationId: number
  templateId: number
  profileId: number
  facilitatorName: string
  languageCode: string
}

export function renderSlideHTML(
  slide: IPresentationSlide,
  step: number | null
): React.ReactElement {
  return unsafeRenderPresentationSlide(slide, step, {
    className: '',
  })
}

/**
 * @TODO there's nothing 'dynamic' about this function at all. it just injects
 *       a different class name into the containing <div> tag. maybe we can give
 *       it a better name, or stop using the 'dynamic' monicer alltogether. the
 *       data isn't dynamic, it's just templated HTML.
 *
 * @see renderSlideHTML
 */
export function renderSlideDynamicHTML(
  slide: IPresentationSlide,
  step: number | null
): React.ReactElement {
  return unsafeRenderPresentationSlide(slide, step, {
    className: '',
  })
}

const initialSlideState: IPresentationSlide = {
  id: 0,
  description: '',
  html: '',
  slideNumber: 0,
  slideSteps: [],
  notes: '',
}

function isLastSlideAndStep(
  slides: ReadonlyArray<IPresentationSlide>,
  position: PresentationPosition
): boolean {
  const numSlides = slides.length
  const steps = slides[numSlides - 1].slideSteps
  return (
    position.slideIndex === numSlides - 1 &&
    ((position.slideStepIndex === null && steps.length === 0) ||
      position.slideStepIndex === steps.length)
  )
}

export const useFacilitatorDelivery = () => {
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const presentationPublicId = queryParams.get(pIdQueryKey) || ''

  const facilitatorSlides: IPresentationSlide[] = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.presentationSlides
  )
  const participantsList = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.participantsList
  )
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const initialPresentationDataState: IPresentationData = {
    presentationId: 0,
    templateId: 0,
    profileId: 0,
    facilitatorName: '',
    languageCode: '',
  }
  const initialParticipantsStatusState: IParticipantsStatus = {
    id: 0,
    isOnline: false,
  }
  const [presentationData, setPresentationData] = useState<IPresentationData>(
    initialPresentationDataState
  )
  const [participants, setParticipants] = useState<IParticipants[]>([])
  const [participantsStatus, setParticipantsStatus] = useState<IParticipantsStatus>(
    initialParticipantsStatusState
  )
  const [startPresentationPage, setStartPresentationPage] = useState(true)
  const [cancelPresentationModal, setCancelPresentationModal] = useState(false)
  const [finalPage, setFinalPage] = useState(false)
  const [participantPublished, setParticipantPublished] = useState(false)
  const [participantDynamicSlideModal, setParticipantDynamicSlideModal] = useState(false)
  const [participantDynamicSlide, setParticipantDynamicSlide] =
    useState<IPresentationSlide>(initialSlideState)
  const controls = usePresentationControls(facilitatorSlides)
  const currentSlide = facilitatorSlides[controls.position.slideIndex]

  useEffect(() => {
    if (presentationPublicId && !presentationData.presentationId) {
      getFacilitatorPresentation(presentationPublicId, dispatch).then((response) => {
        if (response) {
          setPresentationData({
            presentationId: response.coursePresentationId,
            templateId: response.templateId,
            profileId: response.profileId,
            facilitatorName: response.facilitatorName,
            languageCode: response.languageCode,
          })
        }
      })
    }
  }, [presentationPublicId])

  // signalr-connection
  useEffect(() => {
    if (presentationData.presentationId) {
      const connection = new HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_BASE_URL}signalr-delivery/?cpid=${presentationData.presentationId}`
        )
        .withAutomaticReconnect()
        .build()

      connection.on('getParticipantOnlineStatus', (participantId: number, status: boolean) => {
        setParticipantsStatus({
          id: participantId,
          isOnline: status,
        })
      })

      connection
        .start()
        .then((result) => {})
        .catch((e) => {
          dispatch(setNotification(e))
          return Promise.reject(e)
        })
    }
  }, [presentationData.presentationId])

  // Presentation Details
  useEffect(() => {
    const getPresentationDetails = async (): Promise<any> => {
      dispatch(setSpinner(true))
      // Get presentation slides
      await getAllPresentationSlides(presentationData.templateId, dispatch)

      // Get presentation status
      await getPresentationStatus(presentationData.presentationId, dispatch).then((response) => {
        if (response?.status) {
          if (response.status !== CoursePresentationStatus.Planned) setStartPresentationPage(false)
        }
      })

      // Get presentation participants
      await getAllParticipants(
        presentationData.profileId,
        presentationData.presentationId,
        dispatch
      )
    }

    if (presentationData.presentationId) {
      getPresentationDetails().finally(() => dispatch(setSpinner(false)))
    }
  }, [presentationData.presentationId])

  // Participants List
  useEffect(() => {
    const updatedParticipants = [...participantsList]
    updatedParticipants.forEach((participant) => {
      participant.checked = false
      participant.isOnline = false
    })
    setParticipants(updatedParticipants)
  }, [participantsList])

  // Participants Status
  useEffect(() => {
    const updatedParticipants = [...participants]

    const participantIndex = updatedParticipants.findIndex(
      (participant) => participant.profileParticipantId === Number(participantsStatus.id)
    )

    if (updatedParticipants[participantIndex]) {
      updatedParticipants[participantIndex].isOnline = participantsStatus.isOnline
      setParticipants(updatedParticipants)
    }
  }, [participantsStatus])

  // Update Screen/Slide
  useEffect(() => {
    if (currentSlide?.id) {
      UpdateParticipantScreen(
        presentationData.presentationId,
        currentSlide.id,
        controls.position.slideStepIndex,
        dispatch
      )
    }
  }, [presentationData.presentationId, controls.position])

  const startPresentation = async (): Promise<void> => {
    dispatch(setSpinner(true))
    //Here we need to send email in participant language but this is facilitator language. Still we are passing empty string as the API requires this field for ASP purpose.
    updateCoursePresentationStatus(
      presentationData.presentationId,
      '',
      CoursePresentationStatus.Ongoing,
      dispatch
    )
      .then((reponse) => reponse && setStartPresentationPage(false))
      .finally(() => dispatch(setSpinner(false)))
  }

  const previousSlide = (): void => {
    setFinalPage(false)
    controls.previous()
  }

  const nextSlide = (): void => {
    // note that this acts on the current position. if we're on the last
    // slide, let's show the final page.
    const wantsFinalPage = isLastSlideAndStep(facilitatorSlides, controls.position)
    setFinalPage(wantsFinalPage)

    // this is no-op if we're on the last slide and step.
    controls.next()
  }

  const handleSlideClick = (slideNumber: number): void => {
    const index = facilitatorSlides.findIndex((slide) => slide.slideNumber === slideNumber)
    if (index !== -1) {
      controls.setPosition({
        slideIndex: index,
        slideStepIndex: null,
      })
    }
  }

  const handleCancelClick = (): void => setCancelPresentationModal(true)

  const handleCloseModal = (): void => setCancelPresentationModal(false)

  const handleConfirmCancelPresentation = (): void => {
    // Close the current tab
    window.close()
  }

  const handleSelectAllParticipantsForDelivery = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target
    setParticipants((prev) => {
      const updatedParticipants = prev.map((participant) => ({
        ...participant,
        checked: checked,
      }))
      return updatedParticipants
    })
  }

  const handleSelectParticipantForDelivery = (
    e: React.ChangeEvent<HTMLInputElement>,
    profileParticipantId: number
  ): void => {
    const { checked } = e.target

    setParticipants((prev) => {
      const updatedParticipants = prev.map((participant) => {
        if (participant.profileParticipantId === profileParticipantId) {
          return { ...participant, checked: checked }
        }
        return participant
      })
      return updatedParticipants
    })
  }

  const handleFinalPageBackBtn = (): void => setFinalPage(false)

  const publishParticipants = (): void => {
    dispatch(setSpinner(true))
    const deliveryParticipants: number[] = []
    participants.forEach((participant) => {
      if (participant.checked === true) {
        deliveryParticipants.push(participant.profileParticipantId)
      }
    })

    //Here we need to send email in participant language but this is facilitator language. Still we are passing empty string as the API requires this field for ASP purpose.
    const params: IDeliverProfileParticipantsParams = {
      courseId: presentationData.profileId,
      coursePresentationId: presentationData.presentationId,
      finishedMsg: 'Finished',
      languageCode: '',
    }

    deliverProfileParticipants(deliveryParticipants, params, dispatch)
      .then((response) => {
        if (response) {
          setParticipantPublished(true)

          // Close the current tab
          setTimeout(() => {
            window.close()
          }, 5000)
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const openParticipantDynamicSlides = (profileParticipantId: number): void => {
    const queryParmas: IParticipantCurrentSlideParams = {
      slideId: currentSlide.id,
      participantId: profileParticipantId,
      languageCode: presentationData.languageCode,
    }

    getParticipantCurrentSlide(queryParmas, dispatch).then((res) => {
      if (res?.status === 200) {
        setParticipantDynamicSlideModal(true)
        setParticipantDynamicSlide(res.data.result)
      }
    })
  }

  const closeParticipantDynamicSlides = (): void => {
    setParticipantDynamicSlideModal(false)
    setParticipantDynamicSlide(initialSlideState)
  }

  return {
    languageText,
    slideStep: controls.position.slideStepIndex,
    currentSlide,
    facilitatorSlides,
    participants,
    presentationData,
    finalPage,
    startPresentationPage,
    cancelPresentationModal,
    participantPublished,
    participantDynamicSlideModal,
    participantDynamicSlide,
    startPresentation,
    previousSlide,
    nextSlide,
    handleSlideClick,
    handleCancelClick,
    openParticipantDynamicSlides,
    closeParticipantDynamicSlides,
    handleCloseModal,
    handleConfirmCancelPresentation,
    publishParticipants,
    handleSelectAllParticipantsForDelivery,
    handleSelectParticipantForDelivery,
    handleFinalPageBackBtn,
  }
}
