import React from 'react'
import { ModalComponent } from './modalComponent'

interface ICustomModalProps {
  headerText?: string
  bodyText: string | JSX.Element
  cancelButtonText?: string
  submitButtonText?: string
  isSubmitDangerButton?: boolean
  cancelButtonDisabled?: boolean
  submitButtonDisabled?: boolean
  handleSubmitClick?: () => void
  handleCancelClick: () => void
  handleCloseModal?: () => void
}

export const CustomModal = (props: ICustomModalProps) => {
  const {
    headerText,
    bodyText,
    cancelButtonText,
    submitButtonText,
    isSubmitDangerButton,
    cancelButtonDisabled,
    submitButtonDisabled,
    handleSubmitClick,
    handleCancelClick,
    handleCloseModal,
  } = props

  const closeIconClick = (): void => {
    if (handleCloseModal) handleCloseModal()
    else handleCancelClick()
  }

  return (
    <>
      <ModalComponent
        headerText={headerText || ''}
        submitButtonText={submitButtonText}
        submitButtonDisabled={submitButtonDisabled}
        cancelButtonText={cancelButtonText}
        cancelButtonDisabled={cancelButtonDisabled}
        isSubmitDangerButton={isSubmitDangerButton}
        handleSubmitClick={handleSubmitClick}
        handleCancelClick={closeIconClick}
      >
        {bodyText}
      </ModalComponent>
    </>
  )
}
