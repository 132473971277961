import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '@app/store/configureStore'
import { removeNotification } from '../../containers/actions'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { ModalComponent } from '../modals/modalComponent'

interface IErrorToasterProps {
  notificationMessage: string
}

interface ISuccessToastProps {
  id: string
  index: number
  message: string
  onClose: () => void
}

const ErrorToaster = (props: IErrorToasterProps): JSX.Element => {
  const dispatch = useDispatch()
  const modalRef = useRef<HTMLDivElement>(null)
  const { notificationMessage } = props

  // Handling Language
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  // useEffect - Once error notification is displayed, shift keyboard focus to this notification
  // Else keyboard focus will be on background itself
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus()
    }
  }, [])

  const removeNotifications = (): void => {
    dispatch(removeNotification(''))
  }

  return (
    <React.Fragment>
      {notificationMessage && (
        <ModalComponent
          headerText={getLanguageValue(languageText, 'Error')}
          cancelButtonText={getLanguageValue(languageText, 'OK')}
          handleCancelClick={removeNotifications}
        >
          <div className='alert alert-danger'>
            {getLanguageValue(languageText, notificationMessage)}
          </div>
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

const SuccessToast = (props: ISuccessToastProps) => {
  const { id, index, message, onClose } = props
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const position = index * 110 + 10 // Adjust vertical spacing between toasts

  return (
    <div
      key={id}
      className='position-fixed col-8 col-md-4 col-lg-3 col-xxl-2 end-0 p-3 zindex-toast'
      style={{ bottom: `${position}px` }}
    >
      <div
        className='toast show text-bg-success border-0'
        role='alert'
        aria-live='assertive'
        aria-atomic='true'
      >
        <div className='toast-header border-0'>
          <i className='bi bi-check-square-fill text-success fs-5 pe-2' />
          <strong className='me-auto'>{getLanguageValue(languageText, 'Success')}</strong>
          <button type='button' className='btn-close' onClick={onClose} />
        </div>
        <div className='toast-body'>{getLanguageValue(languageText, message)}</div>
      </div>
    </div>
  )
}

export { ErrorToaster, SuccessToast }
