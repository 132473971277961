// Account
enum AccountSelectBtns {
  Select = '1',
  AddNew = '2',
}

// Step 1 (Individual / Group)
enum IndividualGroupBtnsEnum {
  group = '1',
  individual = '2',
}

// Step 2 (Description)
enum DescriptionInputs {
  description = 'description',
  completionDate = 'completionDate',
  culture = 'culture',
  country = 'country',
  notes = 'notes',
}

// Step 3 (Roles)
enum ProfileRoleStatus {
  RoleSetByFacilitator = '1',
  RoleSetByParticipant = '2',
  DisableRole = '3',
}
const getRoleStatusName = (roleStatus: string) => {
  switch (roleStatus) {
    case ProfileRoleStatus.RoleSetByFacilitator:
      return 'Facilitator'
    case ProfileRoleStatus.RoleSetByParticipant:
      return 'Participant'
    case ProfileRoleStatus.DisableRole:
      return 'Role Disabled'
    default:
      return ''
  }
}

enum RoleBtnsEnum {
  sameRole = '1',
  individualRole = '2',
}
const getRoleSettingsName = (roleType: boolean) => {
  switch (roleType) {
    case true:
      return 'Same for all'
    case false:
      return 'Individual role'
    default:
      return ''
  }
}

enum TypeOfRole {
  Colleagues = 1,
  TeamMembers = 2,
  Clients = 3,
  Suppliers = 4,
  Subordinates = 5,
}

enum GetTypeOfRoleName {
  'Colleagues' = 1,
  'Team members' = 2,
  'Clients' = 3,
  'Suppliers' = 4,
  'Subordinates' = 5,
}

enum NoOfRespondents {
  'N3' = '3',
  'N4' = '4',
  'N5' = '5',
  'N6' = '6',
}

enum ParticipantChangeRole {
  canChange = '1',
  cannotChange = '2',
}

// Step 4 (Respondents)
enum InviteRespondents {
  isInviteExternal = 'isInviteExternal',
  isInviteColleagues = 'isInviteColleagues',
  isInviteOtherParticipant = 'isInviteOtherParticipant',
}

// Step 6 (Invite Participants)
enum InvitationCategory {
  same = '1',
  personalized = '2',
}

enum InvitationType {
  email = 1,
  sms = 2,
  emailSMS = 3,
}

// ProfileStatus
enum ProfileStatus {
  Created = 1,
  Active = 2,
  Completed = 3,
  Invoiced = 4,
  Cancelled = 5,
  ProBono = 6,
  Delivered = 7,
}
const profileStatusColors: { [key in ProfileStatus]?: string } = {
  [ProfileStatus.Created]: 'bg-warning bg-opacity-50 text-secondary',
  [ProfileStatus.Active]: 'bg-primary-subtle text-primary',
  [ProfileStatus.Completed]: 'bg-info text-success',
  [ProfileStatus.Invoiced]: 'text-bg-primary text-white',
  [ProfileStatus.Cancelled]: 'text-bg-light',
  [ProfileStatus.ProBono]: 'text-bg-light',
  [ProfileStatus.Delivered]: 'text-bg-success',
}

// Edit profile participant Status
export enum ParticipantProfileStatus {
  Unknown = 0,
  New = 1,
  Active = 2, // Ongoing
  Completed = 3,
  DeliveryPlanned = 4,
  Delivery = 5,
}

enum CoursePresentationStatus {
  Unknown = 0,
  Planned = 1,
  Ongoing = 2,
  Completed = 3,
  Cancelled = 4,
}
const getCoursePresentationStatus = (presentationStatus: CoursePresentationStatus): string => {
  switch (presentationStatus) {
    case CoursePresentationStatus.Planned:
      return 'Planned'
    case CoursePresentationStatus.Ongoing:
      return 'Ongoing'
    case CoursePresentationStatus.Completed:
      return 'Finished'
    case CoursePresentationStatus.Cancelled:
      return 'Cancelled'
    default:
      return ''
  }
}

// ProfileMeasure
enum ProfileMeasure {
  Low = 1,
  Medium = 2,
  High = 3,
}

// Download option (no of pages)
enum ProfileDownloadOption {
  BothPages = 1,
  Page1 = 2,
  Page2 = 3,
}
enum InvoicedFilter {
  All = 0,
  InvoiceCreated = 1,
  InvoiceNotCreated = 2,
}

export {
  AccountSelectBtns,
  IndividualGroupBtnsEnum,
  DescriptionInputs,
  ProfileRoleStatus,
  RoleBtnsEnum,
  TypeOfRole,
  GetTypeOfRoleName,
  ParticipantChangeRole,
  InviteRespondents,
  InvitationCategory,
  InvitationType,
  ProfileStatus,
  profileStatusColors,
  CoursePresentationStatus,
  NoOfRespondents,
  ProfileMeasure,
  ProfileDownloadOption,
  InvoicedFilter,
  getCoursePresentationStatus,
  getRoleSettingsName,
  getRoleStatusName,
}
