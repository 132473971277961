import React, { useState, useEffect, useRef, SyntheticEvent } from 'react'
import clsx from 'clsx'

import { setDropdownOnTop } from '../../commonUtils/scrollFunctionsHelper'
import { IFocusError, KeyCode } from './input'
import { classNames } from '@app/containers/utils'

export interface IDropdownList {
  id: number
  displayName: string | JSX.Element
  value: string
}

export interface IDropdownSelectedItem {
  id: number
  value: string
  name: string
}

interface IDropdownSelectProps {
  label?: string
  name: string
  required?: boolean
  displayClassName?: string
  list: IDropdownList[]
  defaultLabel?: string
  value?: string
  disabled?: boolean
  searchOption?: boolean
  focusInput?: IFocusError
  dropdownHeight?: string
  handleDropdownSelect: (selectedItem: IDropdownSelectedItem) => void
  narrow?: boolean
}

export const DropdownSelect: React.FC<IDropdownSelectProps> = (props) => {
  const {
    label,
    name,
    required,
    displayClassName,
    list,
    defaultLabel,
    disabled,
    value,
    focusInput,
    handleDropdownSelect,
  } = props

  const ref = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [displayName, setDisplayName] = useState<string | JSX.Element>('')
  const [filter, setFilter] = useState<string>('')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [dropdownTop, setDropdownTop] = useState<boolean>(false)
  const searchRef = React.useRef<HTMLInputElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0)
  const selectedItemRef = React.useRef<HTMLLIElement>(null)

  const handleClickOutside = (event: MouseEvent): void => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (value && value !== 'null') {
      const displayName = list.find((item) => item.value === value)?.displayName
      if (displayName) {
        setDisplayName(displayName!)
      } else setDisplayName(defaultLabel!)
    } else setDisplayName(defaultLabel!)
  }, [value, defaultLabel, list]) // defaultLabel is added as dependency since it needs to re-render when language is changed/page is refreshed

  const toggleDropdown = (event: SyntheticEvent): void => {
    event.preventDefault()
    if (disabled) {
      return
    }

    // TODO: these values _probably_ control whether the dropdown appears
    //   above or below the input field. they have not been implemented
    //   yet for BS 5.3.
    //   -johan, 2024-04-29
    const isDropdownBelowThreshold = setDropdownOnTop(ref, 0.6)
    setShowDropdown(!showDropdown)
    if (isDropdownBelowThreshold) {
      setDropdownTop(true)
    } else {
      setDropdownTop(false)
    }

    // highlight the currently selected item, if there is one.
    const index = filteredList.findIndex((item) => item.value === value)
    setSelectedIndex(index ?? 0)
  }

  const handleSelect = (item: IDropdownList): void => {
    if (disabled) {
      return
    }

    const selectedItem: IDropdownSelectedItem = {
      id: item.id,
      value: item.value,
      name: name,
    }
    handleDropdownSelect(selectedItem)
    setShowDropdown(!showDropdown)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFilter(e.target.value)
    setSelectedIndex(0)
  }

  React.useEffect(() => {
    if (showDropdown) {
      searchRef.current?.focus()
    }
  }, [showDropdown])

  const filteredList = list.filter((item) => {
    if (!filter) {
      return true
    }
    if (typeof item.displayName === 'string') {
      return item.displayName.toLowerCase().includes(filter.toLowerCase())
    } else if (typeof item.displayName === 'object') {
      return item.displayName.props.value.toLowerCase().includes(filter.toLowerCase())
    } else {
      return item
    }
  })

  function handleKeyDown(event: React.KeyboardEvent): void {
    switch (event.keyCode) {
      case KeyCode.Enter: {
        event.preventDefault()
        event.stopPropagation()

        const item = filteredList[selectedIndex]
        if (!item) {
          return
        }
        handleSelect(item)
        break
      }
      case KeyCode.Escape:
        event.stopPropagation()
        setShowDropdown(false)
        break
      case KeyCode.ArrowUp: {
        event.preventDefault()
        event.stopPropagation()
        let next = selectedIndex - 1

        // wrap around to the end of the list.
        if (next < 0) {
          next = filteredList.length - 1
        }
        setSelectedIndex(next)
        break
      }
      case KeyCode.ArrowDown: {
        event.preventDefault()
        event.stopPropagation()

        // wrap around to the start of the list.
        const next = (selectedIndex + 1) % filteredList.length
        setSelectedIndex(next)
        break
      }
    }
  }

  React.useEffect(() => {
    selectedItemRef.current?.scrollIntoView({
      block: 'nearest',
    })
  }, [selectedIndex])

  const clazz = classNames({
    'position-relative': true,
    'mb-3': !props.narrow,
  })

  return (
    <div className={clazz} onKeyDown={handleKeyDown}>
      {label && <label className={clsx('mb-1', { required: required })}>{label}</label>}

      <div
        ref={ref}
        className={clsx(
          'd-flex justify-content-between align-items-center form-control',
          displayClassName
        )}
        onClick={toggleDropdown}
        role='button'
      >
        {displayName}
        <i className='bi bi-chevron-down' />
      </div>

      <div
        className={classNames({
          'border-start border-end border-bottom position-absolute w-100 shadow z-1': true,
          'd-none': !showDropdown,
          'd-block': showDropdown,
        })}
        ref={dropdownRef}
      >
        <div className='p-2 bg-white'>
          <div className='input-group'>
            <span className='input-group-text'>
              <i className='bi bi-search' />
            </span>
            <input
              type='text'
              className='form-control'
              value={filter}
              onChange={handleSearch}
              ref={searchRef}
              placeholder='Search'
            />
          </div>
        </div>

        {/* the 'overflow-y-scroll' class is documented but not implemented in BS 5.3. resort to manual styling for now. */}
        <ul className='list-group list-group-flush' style={{ maxHeight: 300, overflowY: 'scroll' }}>
          {filteredList.length > 0 ? (
            filteredList.map((item, idx) => {
              const clazz = classNames({
                'list-group-item list-group-item-action': true,
                active: idx === selectedIndex,
              })

              return (
                <li
                  key={item.id}
                  className={clazz}
                  ref={idx === selectedIndex ? selectedItemRef : undefined}
                  onClick={() => handleSelect(item)}
                  role='button'
                >
                  {item.displayName}
                </li>
              )
            })
          ) : (
            <li className='list-group-item'>No Items to display</li>
          )}
        </ul>
      </div>

      {/* TODO: not implemented */}
      {focusInput?.errorMessage && (
        <div className='invalid-feedback d-inline-block'>{focusInput.errorMessage}</div>
      )}
    </div>
  )
}
