// Extend the global window interface to include Matomo's _mtm property
declare global {
  interface Window {
    _mtm: Array<Record<string, any>>
  }
}

// Define the MatomoRegisterUserEvent type
type MatomoRegisterUserEvent = {
  event: 'register_user'
  fields: {
    name: string
    surname: string
    emailAddress: string
    countries: string
    typeOfUsers: string
  }
}

// Function to push registration events to Matomo
function pushToMatomo(event: MatomoRegisterUserEvent): void {
  // Ensure we only track in production
  if (process.env.NODE_ENV?.toLowerCase() !== 'production') {
    console.warn(`Environment is '${process.env.NODE_ENV}', ignoring Matomo push.`)
    return
  }

  // Initialize _mtm if it doesn't exist
  window._mtm = window._mtm || []

  // Push the registration event to Matomo
  window._mtm.push({
    event: 'mtm.CustomEvent',
    name: event.fields.name,
    surname: event.fields.surname,
    emailAddress: event.fields.emailAddress,
    countries: event.fields.countries,
    typeOfUsers: event.fields.typeOfUsers,
  })
}

export { pushToMatomo }
