import {
  IParticipantProfileGroupReport,
  IParticipantProfileReport,
  IProfileGroupReport,
} from './profileList/editProfile/interface'
import { Map } from '@app/types'

export function truncate(message: string, n: number): string {
  return message.length > n ? `${message.slice(0, n - 1)}...` : message
}

export function emptyParticipantProfileReport(): IParticipantProfileReport {
  return {
    name: '',
    title: '',
    culture: '',
    date: '',
    roleId: 0,
    roleText: '',
    noOfRespondents: 0,
    respondents: [],
    sFlex: 0,
    oFlex: 0,
    oDirPos: 0,
    sDirPos: 0,
    oAffPos: 0,
    sAffPos: 0,
    outsideStdDevD: 0,
    outsideStdDevA: 0,
    outsideStdDevF: 0,
    flexMessage: '',
    directivenessMessage: '',
    affiliationMessage: '',
    reportProfileDescription: '',
    directivenessSpreadText: '',
    affiliationSpreadText: '',
    adaptabilityText1: '',
    adaptabilityRespondentsText: '',
    adaptabilityText2: '',
    adaptabilitySpreadText: '',
    adaptabilityText3: '',
  }
}

export function emptyParticipantProfileGroupReport(): IParticipantProfileGroupReport {
  return {
    averageAdaptability: 0,
    averageAffiliation: 0,
    averageDirectiveness: 0,
    groupReportDirectivenesses: [
      {
        pointId: 0,
        affPos: 0,
        dirPos: 0,
        count: 0,
        profileParticipantIds: [],
      },
    ],
    groupReportAdaptabilityes: [
      {
        adaptabilityIndex: '',
        flex: 0,
        count: 0,
        sMarginTop: '',
        oMarginTop: '',
        profileParticipantIds: [],
      },
    ],
    groupReportParticipants: [
      {
        pointId: 0,
        adaptabilityIndex: '',
        name: '',
        profileParticipantId: 0,
      },
    ],
  }
}

export function emptyProfileGroupReport(): IProfileGroupReport {
  return {
    selfGroupReport: {
      averageAdaptability: 0,
      averageAffiliation: 0,
      averageDirectiveness: 0,
      groupReportDirectivenesses: [],
      groupReportAdaptabilityes: [],
      groupReportParticipants: [],
    },
    otherGroupReport: {
      averageAdaptability: 0,
      averageAffiliation: 0,
      averageDirectiveness: 0,
      groupReportDirectivenesses: [],
      groupReportAdaptabilityes: [],
      groupReportParticipants: [],
    },
  }
}

export function classNames(names: Map<unknown>): string {
  return Object.keys(names)
    .filter((key) => !!names[key])
    .join(' ')
}

function hasMatchingTypes<A>(known: A, other: unknown): other is A {
  if (known === null) {
    return other === null
  }
  if (Array.isArray(known)) {
    return Array.isArray(other)
  }
  if (typeof known === 'object') {
    if (typeof other !== 'object' || other === null) {
      return false
    }
    for (const key in known) {
      if (!hasMatchingTypes(known[key], (other as any)[key])) {
        return false
      }
    }
    return true
  }
  return typeof known === typeof other
}

export function tryParseJson<T>(value: unknown, defaultValue: T): T {
  if (typeof value !== 'string') {
    return defaultValue
  }

  try {
    const parsed = JSON.parse(value)
    if (hasMatchingTypes(defaultValue, parsed)) {
      return parsed
    }
  } catch (e) {
    // nope.
  }

  return defaultValue
}

export function keyBy<T>(
  items: ReadonlyArray<T>,
  getKeyFn: (item: T) => unknown
): { [key: string]: T } {
  const keyed: { [key: string]: T } = {}
  for (const item of items) {
    const key = String(getKeyFn(item))
    if (!(key in keyed)) {
      keyed[key] = item
    }
  }
  return keyed
}

export function uniqueBy<T>(
  items: ReadonlyArray<T>,
  getKeyFn: (item: T) => unknown
): ReadonlyArray<T> {
  const keyed = keyBy(items, getKeyFn)
  return Object.values(keyed)
}

export function hasOwnProperty<K extends string | number>(
  value: unknown,
  prop: K
): value is { [P in K]: unknown } {
  return Object.prototype.hasOwnProperty.call(value, prop)
}
